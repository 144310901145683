















import { Component, Vue } from 'vue-property-decorator'
import Config from '@/config/default/config'

@Component
export default class ErrorAuthLkPage extends Vue {
  private errorText = `Вход через личный кабинет доступен только пользователям, прошедшим
        регистрацию в ИС "${Config.systemTitle.fullName}"`
  private toMainPage() {
    this.$router.push(Config.routing.homePage.path)
  }
}
